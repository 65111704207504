
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EIUCommonLayouts from "@/views/resources/documentation/element-ui/basic/layout-container/CommonLayouts.vue";
import EIUExample from "@/views/resources/documentation/element-ui/basic/layout-container/Example.vue";

export default defineComponent({
  name: "layout",
  components: {
    EIUCommonLayouts,
    EIUExample
  },
  setup() {
    setCurrentPageTitle("Layout Container");
  }
});
